<template>
  <b-card
    class="full-height"
    :class="{popular: isPopular}"
    no-body
  >
    <div class="card-body d-flex flex-column justify-content-between flex-fill">
      <div>
        <div class="text-right mb-2">
          <b-badge
            v-if="isPopular"
            pill
            variant="light-primary"
          >
            {{ $t('page.plans.labels.popular') }}
          </b-badge>
          <div v-else>
            <br>
          </div>
        </div>
        <div
          style="min-height: 50px"
          class="d-flex justify-content-center align-items-center"
        >
          <h3 class="mb-0 text-center">
            {{ plan.name }}
          </h3>
        </div>
        <b-card-text class="text-center">
          {{ plan.description }}
        </b-card-text>
        <b-card-text class="text-center">
          <div class="pt-2">
            <sup
              class="text-primary font-medium-3"
              style="top: -1.2rem; left: -0.2rem"
            >
              {{ $t('currency') }}
            </sup>
            <span class="text-primary font-weight-bolder font-large-2">
              {{ plan.price | price(0) }}
            </span>
            <sub
              v-if="selectedPeriod"
              class="font-medium-1"
              style="left: -0.7rem;top: -0.1rem"
            >/ {{ $t(`page.plans.labels.${selectedPeriod.title}`).toLowerCase() }}</sub>
          </div>
        </b-card-text>
        <b-list-group
          v-if="plan.capabilities"
          class="list-group-circle text-start"
        >
          <b-list-group-item
            v-for="(item, key) in plan.capabilities"
            :key="key"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span>{{ item.name }}</span>
              <a
                v-if="item.url"
                :href="item.url"
                target="_blank"
                class="cursor-pointer text-primary"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  size="19"
                />
              </a>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <b-card-text class="pt-2">
        <b-button
          v-if="isActive"
          :variant="isPopular ? 'primary' : 'outline-primary'"
          block
          @click="subscribe"
        >
          <span>{{ $t('page.plans.labels.choose') }}</span>
        </b-button>
        <div
          v-else
          class="d-flex align-items-center justify-content-center"
          style="padding-bottom: 5px"
        >
          <p class="text-primary font-medium-3 mb-0 font-weight-bold">
            {{ $t('page.plans.labels.soon') }}
          </p>
        </div>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton,
  BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  name: 'Plan',
  components: {
    BCard,
    BCardText,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    selectedPeriod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    isPopular() {
      return this.plan.popular
    },
    isActive() {
      return this.plan.status === 'active' || this.plan.status === undefined
    },
  },
  methods: {
    ...mapActions({
      subscribeToPlan: 'subscription/subscribeToPlan',
    }),
    subscribe() {
      this.subscribeToPlan(this.plan.id)
    },
  },
}
</script>

<style scoped>

</style>
