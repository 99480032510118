<template>
  <b-row class="d-flex justify-content-center align-self-stretch">
    <b-col
      v-for="(plan, key) in plansByPeriod"
      :key="key"
      class="col-12 col-md-6 col-xl-4 mb-2"
    >
      <plan
        :selected-period="selectedPeriod"
        :plan="plan"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import Plan from '@/components/subscriptions/plan/Plan.vue'

export default {
  name: 'Plans',
  components: {
    Plan,
    BRow,
    BCol,
  },
  props: {
    selectedPeriod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    customPlansDescription: {
      'Тариф Профессиональный': {
        capabilities: [
          {
            name: 'Все возможности тарифа "Базовый"',
          },
          {
            name: 'Доступ к готовым курсам',
          },
          {
            name: 'Доступ к готовым упражнениям',
          },
          {
            name: 'Конструктор тестов и упражнений',
          },
          {
            name: 'Количество учеников неограниченно',
          },
        ],
        status: 'active',
      },
      'Профи Plus': {
        capabilities: [
          {
            name: 'Все возможности тарифа "Профессиональный"',
          },
          {
            name: 'Видеосвязь до 4 учеников',
            url: '#',
          },
          {
            name: 'Доступ ко всем заявкам',
            url: '#',
          },
        ],
        status: 'disabled',
      },
    },
  }),
  computed: {
    ...mapGetters({
      plans: 'subscription/get_plans',
    }),
    plansByPeriod() {
      let plans = Object.assign([], this.plans)
      if (this.selectedPeriod) plans = plans.filter(plan => plan.period === this.selectedPeriod.days)
      plans = plans.map(plan => {
        const currentPlan = plan
        const customPlan = this.customPlansDescription[plan.name]
        if (customPlan) {
          currentPlan.capabilities = customPlan.capabilities
          currentPlan.status = customPlan.status
        }
        return currentPlan
      })
      return plans
    },
  },
  async created() {
    await this.getPlans()
  },
  methods: {
    ...mapActions({
      getPlans: 'subscription/getPlans',
    }),
  },
}
</script>

<style scoped>

</style>
