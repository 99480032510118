<template>
  <div class="card">
    <b-row>
      <b-col
        cols="12"
        :xl="hideImageOnTablet ? 7 : 7"
        :lg="hideImageOnTablet ? 6 : 6"
        :md="hideImageOnTablet ? 12 : 6"
        order="1"
        order-md="1"
      >
        <div
          v-if="subscription"
          class="card-body"
        >
          <b-card-text>
            <b-card-title>
              {{ $t('page.subscriptions.labels.active.title') }}
            </b-card-title>
            <p class="mb-0">
              {{ $t('page.subscriptions.labels.active.subtitle') }} – <b>{{ tariff.name }}</b>
            </p>
            <p class="mb-0">
              <span class="text-primary font-weight-bolder font-medium-3">{{ $t('currency') }}{{ tariff.price | price }}</span>
              {{ $t('page.subscriptions.labels.per_month') }}
            </p>
            <p class="text-secondary text-muted font-small-3">
              {{ tariff.description }}
            </p>
          </b-card-text>
          <b-card-text v-if="daysRemaining > 0">
            <p class="mb-0">
              {{ $t('page.subscriptions.labels.active.days_remaining') }} <b>{{ subscription.end | dateWithMonthName }}</b>
            </p>
            <p class="text-muted font-small-3">
              {{ $t('page.subscriptions.labels.active.expire_notification') }}
            </p>
          </b-card-text>
          <b-card-text v-else>
            <p class="mb-0 text-danger">
              {{ $t('page.subscriptions.labels.active.expired') }} <b>{{ subscription.end | dateWithMonthName }}</b>
            </p>
            <p class="text-muted font-small-3">
              {{ $t('page.subscriptions.labels.active.expired_notification') }}
            </p>
          </b-card-text>
          <b-card-text>
            <div class="d-flex align-items-center justify-content-between font-weight-bolder">
              <span>{{ $t('page.subscriptions.labels.days') }} </span>
              <span>{{ daysRemaining }} {{ $t('page.subscriptions.labels.from') }} {{ tariff.period }}</span>
            </div>
            <b-progress
              :min="0"
              :max="tariff.period"
              style="margin-top: 5px; margin-bottom: 5px"
              :variant="progressBarColor"
              :value="tariff.period - daysRemaining"
            />
            <p v-if="daysRemaining > 0">
              {{ $t('page.subscriptions.labels.active.expire_days_remaining') }} <b>{{ daysRemaining }}</b>
            </p>
            <p v-else>
              {{ $t('page.subscriptions.labels.active.expired') }}
            </p>
          </b-card-text>
          <b-card-text>
            <b-button
              class="mr-1"
              variant="primary"
              type="button"
            >
              {{ $t('page.subscriptions.labels.active.update_plan') }}
            </b-button>
            <b-button
              v-scroll-to="'#plans'"
              variant="outline-primary"
              type="button"
              :to="{name: 'cabinet.subscriptions', hash: '#plans'}"
            >
              {{ $t('page.subscriptions.labels.active.change_plan') }}
            </b-button>
          </b-card-text>
        </div>
        <div
          v-else
          class="card-body"
        >
          <b-card-text>
            <b-card-title>
              {{ $t('page.subscriptions.labels.active.title') }}
            </b-card-title>
            <p class="mb-0">
              {{ $t('page.subscriptions.labels.active.subtitle') }} – <b>{{ $t('page.subscriptions.labels.no_subscription.base_plan') }}</b>
            </p>
            <p class="mb-0">
              <span class="text-primary font-weight-bolder font-medium-3">{{ $t('free') }}</span>
            </p>
            <p class="text-secondary text-muted font-small-3">
              {{ $t('page.subscriptions.labels.no_subscription.base_plan_description') }}
            </p>
            <capabilites
              :list="subscriptionCapabilities"
            />
          </b-card-text>
          <b-card-text v-if="false">
            <b-button
              v-scroll-to="'#plans'"
              variant="primary"
              type="button"
              :block="currentBreakPoint == 'xs'"
              :to="{name: 'cabinet.subscriptions', hash: '#plans'}"
            >
              {{ $t('page.subscriptions.labels.no_subscription.choose_plan') }}
            </b-button>
          </b-card-text>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="5"
        lg="6"
        md="6"
        :class="hideImageOnTablet ? 'd-none d-lg-block': ''"
        order="0"
        order-md="2"
      >
        <div class="d-flex justify-content-center align-items-end full-height">
          <b-img
            class="img-fluid"
            :src="require('@/assets/images/pages/pricing-Illustration.svg')"
            alt
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BProgress,
  BCardTitle, BCardText, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Capabilites from '@/components/subscriptions/subscription/Capabilites.vue'
import { diffBetweenDates } from '@/helpers'

export default {
  name: 'Subscription',
  components: {
    Capabilites,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BImg,
    BProgress,
    BButton,
  },
  props: {
    hideImageOnTablet: {
      type: Boolean,
      required: false,
    },
    subscription: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      currentBreakPoint: 'app/currentBreakPoint',
    }),
    tariff() {
      return this.subscription.tariff
    },
    daysRemaining() {
      if (!this.subscription) return 0
      const hoursRemaining = diffBetweenDates(this.subscription.end, Date.now(), ['hours'])
      let daysRemaining = Math.ceil(hoursRemaining / 24)
      if (hoursRemaining < 24 && hoursRemaining > 0) daysRemaining = 1
      else if (daysRemaining < 0) daysRemaining = 0
      return daysRemaining
    },
    progressBarColor() {
      let color = 'primary'
      if (this.daysRemaining <= 3) color = 'warning'
      if (this.daysRemaining < 1) color = 'danger'
      return color
    },
    subscriptionCapabilities() {
      if (this.subscription) {
        return this.subscription.capabilites
      }
      return this.$t('page.subscriptions.labels.no_subscription.capabilites.list')
    },
  },
  methods: {
    ...mapActions({
      renewSubscription: 'subscription/renewSubscription',
      cancelSubscription: 'subscription/cancelSubscription',
    }),
  },
}
</script>

<style scoped>

</style>
