<template>
  <b-row>
    <b-col
      v-for="(subscription, sub_key) in subscriptions"
      :key="sub_key"
      cols="12"
    >
      <subscription
        :hide-image-on-tablet="hideImageOnTablet"
        :subscription="subscription"
      />
    </b-col>
    <b-col
      v-if="!subscriptions.length"
      cols="12"
    >
      <subscription
        :hide-image-on-tablet="hideImageOnTablet"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Subscription from '@/components/subscriptions/subscription/Subscription.vue'

export default {
  name: 'Subscriptions',
  components: {
    BRow,
    BCol,
    Subscription,
  },
  props: {
    hideImageOnTablet: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      subscriptions: 'subscription/get_subscriptions',
    }),
  },
  async created() {
    // await this.getSubscriptions()
  },
  methods: {
    ...mapActions({
      getSubscriptions: 'subscription/getSubscriptions',
    }),
  },
}
</script>

<style scoped>

</style>
