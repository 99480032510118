<template>
  <div>
    <subscriptions />
    <div
      v-if="false"
      id="plans"
      class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-4"
    >
      <div>
        <h1>
          {{ $t('page.plans.title') }}
        </h1>
        <p>
          {{ $t('page.plans.labels.subtitle') }}
        </p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button-toolbar>
          <b-button-group>
            <b-button
              v-for="(period, period_key) in periods"
              :key="period_key"
              :variant="selectedPeriod && period.days === selectedPeriod.days ? 'primary' : 'outline-primary'"
              @click="selectedPeriod = period"
            >
              {{ $t(`page.plans.labels.${period.title}`) }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
    </div>
    <div
      v-if="false"
      class="mt-3 pb-5"
    >
      <plans
        :selected-period="selectedPeriod"
      />
    </div>
  </div>
</template>

<script>
import {
  BButtonToolbar, BButtonGroup, BButton,
} from 'bootstrap-vue'
import Subscriptions from '@/components/subscriptions/Subscriptions.vue'
import Plans from '@/components/subscriptions/Plans.vue'

export default {
  name: 'SubscriptionsAndPlans',
  components: {
    Plans,
    Subscriptions,
    BButtonToolbar,
    BButtonGroup,
    BButton,
  },
  data: () => ({
    periods: [
      {
        days: 30,
        title: 'month',
      },
      {
        days: 365,
        title: 'year',
      },
    ],
    selectedPeriod: null,
  }),
  created() {
    this.selectedPeriod = { ...this.periods[0] }
  },
}
</script>

<style scoped>

</style>
