<template>
  <b-list-group class="list-group-circle">
    <b-list-group-item
      v-for="(item, item_key) in list"
      :key="item_key"
      class="list-group--dense"
    >
      {{ item }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'Capabilites',
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
